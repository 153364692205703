import { createContext } from 'react';

import { useLocalStorageValue } from '@react-hookz/web';

import { ApiSettings, ParsedProduct } from '@hultafors/shared/types';

import { toggleFavorite } from '@hultafors/eripiowear/helpers';

export interface FavoritesContextValue {
  favorites: ParsedProduct[];
  toggleFavoriteItem(product: ParsedProduct, isFavorite: boolean): void;
  deleteFavorites(): void;
  isProductFavorite(sku: string): boolean;
}

export const FavoritesContext = createContext<FavoritesContextValue | null>(
  null,
);

export interface FavoritesProviderProps {
  children?: React.ReactNode;
  settings: ApiSettings;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
  settings,
}) => {
  const KEY = 'eripiowear_favorites';
  const favorites = useLocalStorageValue<ParsedProduct[]>(
    `${KEY}-${settings?.market || 'com'}`,
    { defaultValue: [], initializeWithValue: false },
  );

  const toggleFavoriteItem = (product: any, isFavorite: boolean) => {
    const result = toggleFavorite({
      isFavorite,
      items: favorites.value || [],
      product,
    });
    favorites.set(result as ParsedProduct[]);
  };

  const deleteFavorites = () => {
    favorites.set([]);
  };

  const isProductFavorite = (sku: string): boolean =>
    favorites?.value?.find((x) => x.sku === sku) ? true : false;

  return (
    <FavoritesContext.Provider
      value={{
        deleteFavorites,
        favorites: favorites.value || [],
        isProductFavorite,
        toggleFavoriteItem,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
